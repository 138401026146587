body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-error,
.input-error::placeholder {
  color: red;
}


.dropdown-error .Dropdown-control {
  color: red;
}

.Dropdown-root .Dropdown-control {
  padding: 0px 0px 0px 0px;
}

.preserve-spaces {
  white-space: pre-wrap;
}


/* div.Dropdown-arrow-wrapper > span {
  border: 0px;
} */

.input-dropdown {
  height: 30px; /* Adjust as needed */
  white-space: pre-wrap;
}